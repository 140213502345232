import * as React from 'react';

import { Template, Page, ErrorPage } from 'common/layout';

const ResultsNotFound = () => {
  return (
    <Template>
      <Page useSimpleMenu>
        <ErrorPage
          title="Sorry, er is iets misgegaan!"
          description="Je resultaten kunnen helaas niet geladen worden. Geen paniek, probeer het nog eens of vul de questionnaire opnieuw in."
          secondaryButton={{
            title: 'Opnieuw invullen',
            to: '/questions/userType',
          }}
        />
      </Page>
    </Template>
  );
};

export default ResultsNotFound;
